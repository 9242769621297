import { computed, createApp, ref } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import ClickOutsideEvent from './utils/ClickOutsideEvent'
import type { Role } from './types/UserInterface'
import Swal from '@/utils/SweetAlert'
import mitt from 'mitt'
import { onIdle } from './utils/OnIdle'
import type { MediaDetails, MediaResponse } from './types/MediaInterface'
import { last } from 'lodash'

const app = createApp(App)
app.directive('click-outside', ClickOutsideEvent)
app.config.globalProperties.$api = 'https://dev-api.mozisarok.hu'
/* app.config.globalProperties.$api = 'http://145.236.40.210:3000' */

const token = ref<string | undefined>(localStorage.getItem('token') || undefined)
const role = ref<Role>({ id: 0, role: 'default', formatted: 'Látogató' })
const lastwatched = ref<MediaResponse | undefined>(undefined)

app.config.globalProperties.$token = computed({
  get: () => {
    return token.value
  },
  set: (value: string | undefined) => {
    if (value) {
      localStorage.setItem('token', value)
    } else {
      localStorage.removeItem('token')
    }

    token.value = value
    updateUserRole()
  }
})

app.config.globalProperties.$user = computed(() => {
  if (token.value) {
    const t = JSON.parse(atob(token.value.split('.')[1]))

    if (t.exp < Date.now() / 1000) {
      app.config.globalProperties.$token.value = undefined
    } else {
      validateToken()
      return t
    }
  }

  return undefined
})

app.config.globalProperties.$role = computed({
  get: () => role.value,
  set: (value: Role) => (role.value = value)
})

const updateUserRole = () =>
  onIdle(() => {
    app.config.globalProperties.$role.value = { id: 1, name: 'default', formatted: 'Látogató' }

    if (token.value) {
      axios
        .get(`${app.config.globalProperties.$api}/user/role`, {
          headers: {
            Authorization: `Bearer ${token.value}`
          }
        })
        .then((r) => {
          app.config.globalProperties.$role.value = r.data
        })
        .catch()
    }
  })

const validateToken = () =>
  onIdle(() => {
    if (!token.value) return

    axios
      .get(`${app.config.globalProperties.$api}/auth/validate`, {
        headers: {
          Authorization: `Bearer ${token.value}`
        }
      })
      .catch(() => {
        app.config.globalProperties.$token.value = undefined
      })
  })

function getEasterDates(year: number) {
  let f = Math.floor,
    G = year % 19,
    C = f(year / 100),
    H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
    I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
    J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
    L = I - J,
    month = 3 + f((L + 40) / 44),
    day = L + 28 - 31 * f(month / 4)

  const start = new Date(year, month - 1, day - 7) // Húsvét előtti hét
  const end = new Date(year, month - 1, day + 7) // Húsvét utáni hét
  return { start, end }
}

function getSeasonalDates(year: number) {
  return {
    halloween: {
      start: new Date(year, 9, 20), // Október 20.
      end: new Date(year, 10, 1) // November 1.
    },
    christmas: {
      start: new Date(year, 11, 1), // December 1.
      end: new Date(year, 11, 26) // December 26.
    },
    newYear: {
      start: new Date(year, 11, 27), // December 27.
      end: new Date(year + 1, 0, 5) // Január 5.
    },
    easter: getEasterDates(year)
  }
}

const getCurrentHoliday = () => {
  const today = new Date()
  const year = today.getFullYear()
  const dates = getSeasonalDates(year)

  if (today >= dates.halloween.start && today <= dates.halloween.end) {
    return 'halloween'
  } else if (today >= dates.christmas.start && today <= dates.christmas.end) {
    return 'christmas'
  } else if (today >= dates.newYear.start && today <= dates.newYear.end) {
    return 'newyear'
  } else if (today >= dates.easter.start && today <= dates.easter.end) {
    return 'easter'
  } else {
    return ''
  }
}

function deleteOldarThan(days: number) {
  const keys = Object.keys(localStorage)
  const now = Date.now()
  keys.forEach((key) => {
    if (key.includes('movie-') || key.includes('tv-') || key.includes('person-')) {
      const item = JSON.parse(localStorage.getItem(key) || '')
      if (now - item.last_changed > days * 24 * 60 * 60 * 1000) {
        localStorage.removeItem(key)
      }
    }
  })
}

deleteOldarThan(7)

function getMediaDetails(mediaId: number, mediaType: 'movie' | 'tv' | 'person'): Promise<MediaDetails | undefined> {
  const resJSON = localStorage.getItem(`${mediaType}-${mediaId}`)
  var res: MediaDetails | undefined = undefined
  if (resJSON) {
    res = JSON.parse(resJSON)
  }

  return axios
    .get(`${app.config.globalProperties.$api}/media/${mediaType}/${mediaId}`)
    .then((r) => {
      r.data.last_changed = Math.floor(Date.now() / 1000)
      localStorage.setItem(`${mediaType}-${mediaId}`, JSON.stringify(r.data))
      return r.data
    })
    .catch((error) => {
      if (error.response && error.response.data && error.response.data.message) {
        console.log(router.currentRoute.value.path)
        if (router.currentRoute.value.path.startsWith(`/${mediaType}/${mediaId}`)) {
          app.config.globalProperties.$msg('info', error.response.data.message)
          router.push({ name: 'home' })
        }
      }
      return undefined
    })
}

app.config.globalProperties.$lastWatched = computed({
  get: () => {
    if (!lastwatched.value) {
      getLastWatchedTitle().then((res) => {
        lastwatched.value = res
      })
    }

    return lastwatched.value
  },
  set: (value: MediaResponse | undefined) => {
    lastwatched.value = value
  }
})

function getLastWatchedTitle(): Promise<MediaResponse | undefined> {
  if (!token.value) {
    return Promise.resolve(undefined)
  }

  return axios
    .get(`${app.config.globalProperties.$api}/user/history?limit=1`, {
      headers: {
        Authorization: `Bearer ${token.value}`
      }
    })
    .then((r) => {
      if (r.data.results.length === 0) return undefined
      return r.data.results[0] as MediaResponse
    })
    .catch((error) => {
      if (error.response?.data?.message) {
        console.error('Error fetching last watched:', error.response.data.message)
      }
      return undefined
    })
}

// Translate untranslated genres
localStorage.setItem(`genre-10759`, 'Akció és Kaland')
localStorage.setItem(`genre-10762`, 'Mese')
localStorage.setItem(`genre-10763`, 'Hír')
localStorage.setItem(`genre-10764`, 'Valóságshow')
localStorage.setItem(`genre-10766`, 'Beszélgetős műsor')
localStorage.setItem(`genre-10768`, 'Háború és Politika')
localStorage.setItem(`genre-10767`, 'Beszélgetős műsor')

function getGenreName(genreId: number) {
  return localStorage.getItem(`genre-${genreId}`)
}

function setGenreName(genreId: number, genreName: string) {
  localStorage.setItem(`genre-${genreId}`, genreName)
}

app.config.globalProperties.$transparent = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
app.config.globalProperties.$imageURL = 'https://image.tmdb.org/t/p/'
app.config.globalProperties.$currentHoliday = getCurrentHoliday()
app.config.globalProperties.$getMediaDetails = getMediaDetails
app.config.globalProperties.$getGenreName = getGenreName
app.config.globalProperties.$setGenreName = setGenreName

const emitter = mitt()
app.config.globalProperties.$emitter = emitter

if (token.value) {
  updateUserRole()
}

app.provide('global', app.config.globalProperties)

app.use(router).use(VueAxios, axios).use(Swal).mount('#app')
