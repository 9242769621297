import { inject } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean
    requiresAdmin?: boolean
    hideForAuth?: boolean
  }
}

const routes = [  
  { path: '/', name: 'home', component: () => import('@/views/Home.vue')},
  { path: '/movie/:id', name: 'movie', component: () => import('@/views/details/MovieView.vue')},
  { path: '/person/:id', name: 'person', component: () => import('@/views/details/PersonView.vue')},
  { path: '/genre/:media_type/:id', name: 'genre', component: () => import('@/views/details/GenreView.vue')},
  { path: '/tv/:id', name: 'tv', component: () => import('@/views/details/TVView.vue')},
  { path: '/profile/:username', name: 'profile', component: () => import('@/views/profile/UserProfile.vue')},
  { path: '/history', name: 'history', component: () => import('@/views/profile/UserHistory.vue'), meta: { requiresAuth: true} },
  { path: '/mylist', name: 'mylist', component: () => import('@/views/profile/UserList.vue'), meta: { requiresAuth: true} },
  { path: '/success', name: 'success', component: () => import('@/views/SuccessPayment.vue')},
  { path: '/legal/:pageId', name: 'legal', component: () => import('@/views/LegalPage.vue')},
  
  { path: "/:pathMatch(.*)*", name: '404', component: () => import('@/views/errors/404.vue') },
]

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.path === from.path && JSON.stringify(to.query) !== JSON.stringify(from.query)) {
      return false; 
    }
  
    if (savedPosition) {
      return { top: savedPosition.top, left: savedPosition.left, behavior: 'smooth' };
    }
  
    return { top: 0, behavior: 'smooth' };
  }
  
})

router.beforeEach((to, from, next) => {
  const global = inject('global') as Record<string, any>
  
  if(to.meta.requiresAuth === true && global.$token.value === undefined) {
    next({ name: 'home' })
    return;
  } 

  if(to.meta.requiresAdmin === true && global.$role.value.role !== "admin") {
    next({ name: 'home' })
    return;
  }

  if(to.meta.hideForAuth === true && global.$token.value !== undefined) {
    next({ name: 'home' })
    return;
  }

  next();
});

export default router
